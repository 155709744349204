<template>
  <section>
    <b-row class="match-height">
      <template v-for="(linea, key) in allLines">
        <b-col
          v-if="linea.data.length"
          :key="key"
          lg="4"
          sm="4"
        >
          <statistic-card-with-area-chart
            icon="BoxIcon"
            :statistic="sumArr(linea.data)"
            :statistic-title="`Linea ${key+1}`"
            :chart-data="[linea]"
          />
        </b-col>
      </template>
    </b-row>
  </section>
</template>

<script>

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import pantaleon from '@/../apis/Laboratorio'

export default {
  components: {
    BCol,
    BRow,
    StatisticCardWithAreaChart,
  },
  data() {
    return {
      allLines: [],
      qtyLines: 16,
    }
  },
  mounted() {
    const allRequests = []
    for (let index = 1; index < this.qtyLines; index += 1) {
      allRequests.push(pantaleon.actions.datosLinea({ line: index }))
    }
    Promise.all(allRequests).then(values => {
      const arrayData = values.map(subarray => subarray.data)
      arrayData.map(subarray => {
        const seriesArr = subarray.map(series => parseFloat(series[1]).toFixed(2))
        this.allLines.push({data: seriesArr, name: 'Envasado'})
      })
    })
  },
  methods: {
    kFormatter,
    sumArr(arr) {
      try {
        let sum = 0
        arr.map(x => sum += parseFloat(x))
        return `${(sum / 1000).toFixed(2)}t`
      } catch (error) {
        console.error('No se pudo realizar la suma de las series', error)
        return 'X'
      }
    },
  },
}
</script>
